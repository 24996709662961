import hark from "./hark";
import { useState, useEffect, useRef } from "react";

export const useUserSpeakingDetector = ({ stream, onSpeakingChange }) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const harker = useRef(null);
  const destroy = () => {
    if (harker.current?.destroy) {
      harker.current.destroy();
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (stream) {
      harker.current = hark(stream, {
        interval: 0.01,
        threshold: -30,
      });
      harker.current.on("speaking", () => {
        console.log("speaking");
        onSpeakingChange("speaking");
        setIsSpeaking(true);
      });
      harker.current.on("stopped_speaking", () => {
        console.log("stopped_speaking");
        onSpeakingChange("stopped_speaking");
        setIsSpeaking(false);
      });
    }
    return () => {
      if (harker.current?.destroy) {
        // harker.current.destroy();
      }
    };
  }, [stream, harker]);

  return { isSpeaking, destroy };
};
